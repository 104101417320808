// Import necessary libraries and components
import React, { useEffect, useState} from 'react'
import { color, motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './About.scss'

// Define the About component
const About = () => {
  // Declare state for abouts data
  const [abouts, setAbouts] = useState([])
  
  // Fetch abouts data on component mount
  useEffect(() => {
    const query = '*[_type == "abouts"]'
    client.fetch(query).then((data) =>
      setAbouts(data))
  }, [])

  // Render the About component
  return (
    <>
    {/* Display the header text */}
<h2 className='head-text' >
  Running on a <sup><del className='line'>Binary</del></sup> Ternary System: <br />

  <span >
    Bytes
   
  </span>,  <span className='coupon-code'></span>    
   <span >                                        
  Pexels  
   
  </span>  and <span>Tunes</span>
</h2>
    {/* Map through the abouts data and display each item */}
    <div className='app__profiles'>
        {abouts.map((about, index) => (
          // Add motion effects to each item
          <motion.div
          whileInView={{ opacity: 1 }}
         
          
          className='app__profile-item'
          key={about.title + index}>
            {/* Display the image */}
            <motion.img whileHover={{ scale: .9 }} transition={{ duration: 0.5, type: 'tween' }} src={urlFor(about.imgUrl)} className='app__profile-item-img' alt={about.title} />
            {/* Display the title */}
            <h2 className='bold-text' style={{ marginTop: 20 }}>{about.title}</h2>
            {/* Display the description */}
            <p className='p-text' style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
    </div>
    </>
)}

// Export the wrapped About component
export default AppWrap(
   MotionWrap(About, 'app__about'),
   'about',
   "app__whitebg"
);