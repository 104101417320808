// This code renders the main App component and imports all necessary components and containers
// It also imports the App.scss file for styling

import React, { useEffect, useRef } from 'react'
import { About, Footer, Header, Skills, Work } from './container'
import { Navbar, SocialMedia } from './components'
import NavigationDots from './components/NavigationDots';
import './App.scss'
import $ from 'jquery';
import UAParser from 'ua-parser-js';

const App = (Component, idName, classNames) => {
  const cursorRef = useRef(null);

  useEffect(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    
    if (result.browser.name === 'Safari') {
      document.querySelector('.jumbo').classList.add('hide-jumbo');
    }

    const observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            $(entry.target).addClass("animate-slide-down");
          } else {
            $(entry.target).removeClass("animate-slide-down");
          }
        }
      },
      {
        rootMargin: "300px 0px 300px 0px"
      }
    );

    $('[data-animate="true"]').each(function (i) {
      observer.observe(this);
    });

    const moveCursor = (e) => {
      $(".ring").css(
        "transform",
        `translateX(calc(${e.clientX}px - 1.25rem)) translateY(calc(${e.clientY}px - 1.25rem))`
      );
    };

    window.addEventListener('mousemove', moveCursor);

    const intervalId = setInterval(() => {
      makeAStarFall();
    }, 2400);

    var progressPath = document.querySelector('.progress-wrap path');
    var pathLength = progressPath.getTotalLength();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
    progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';		

    var updateProgress = function () {
      var scroll = $(window).scrollTop();
      var height = $(document).height() - $(window).height();
      var progress = pathLength - (scroll * pathLength / height);
      progressPath.style.strokeDashoffset = progress;
    }

    updateProgress();
    $(window).scroll(updateProgress);	

    var offset = 50;
    var duration = 250;

    $(window).on('scroll', function() {
      if ($(this).scrollTop() > offset) {
        $('.progress-wrap').addClass('active-progress');
      } else {
        $('.progress-wrap').removeClass('active-progress');
      }
    });				

    $('.progress-wrap').on('click', function(event) {
      event.preventDefault();
      $('html, body').animate({scrollTop: 0}, duration);
      return false;
    });

    return () => {
      observer.disconnect();
      window.removeEventListener('mousemove', moveCursor);
      clearInterval(intervalId);
    };
  }, []);

  const makeAStarFall = () => {
    var min = -200; 
    var max = 1800;

    var startPosLeft = Math.floor((Math.random() * max) + min);
    var stopPosLeft = Math.floor((Math.random() * max) + min);

    $('<div>').addClass("star falling-star").insertAfter('.stars');

    $('.falling-star').css("left", startPosLeft);

    setTimeout(function(){
      $(".falling-star").addClass("go-falling");
      $('.go-falling').css("left", stopPosLeft);
    },400);

    setTimeout(function(){
       $(".falling-star").remove();
    },1800);
  };

  return (
    <div>
      <div class="stars"></div>
      <div className='jumbo'></div>
      <div className='app'>
        <Navbar />
        <Header />
        <div className="fixed-position app__navigation"> 
          <NavigationDots /> 
        </div>   
        <div className="socials"> 
          <SocialMedia /> 
        </div>
        <About />
        <Work />
        <Skills />
        <Footer />
        <div id="cursor" class="cursor" ref={cursorRef}>
          <div class="ring">
            <div></div>
          </div>
          <div class="ring">
            <div></div>   
          </div>
        </div>
        <div class="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
			<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>

		</svg>
	</div>
      </div>
    </div>
  );
}

export default App;
