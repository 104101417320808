import React from 'react'
import { BsGithub, BsLinkedin, BsWhatsapp, BsBehance } from 'react-icons/bs'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
        <a href='https://www.linkedin.com/in/abubkrahmed/' target='_blank'>
            <BsLinkedin />
        </a>
        </div>
        <div>
        <a href='https://github.com/M1zir' target='_blank'>
            <BsGithub />
        </a>
        </div>
        <div>
        <a href='https://wa.me/+971568906808' target='_blank'>
            <BsWhatsapp />
        </a>
        </div>
        <div>
        <a href='https://www.behance.net/AbubakrAhmad' target='_blank'>
            <BsBehance />
        </a>
        </div>
    </div>
  )
}

export default SocialMedia