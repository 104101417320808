import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import './Header.scss';


const Header = () => { 

  useEffect(() => {
    const hideArrowsOnScroll = () => {
      const arrowFirst = document.querySelector('.arrow-first');
      const arrowSecond = document.querySelector('.arrow-second');
      if (window.scrollY > 0) {
        arrowFirst.classList.add('hide-arrow');
        arrowSecond.classList.add('hide-arrow');
      } else {
        arrowFirst.classList.remove('hide-arrow');
        arrowSecond.classList.remove('hide-arrow');
      }
    };

    window.addEventListener('scroll', hideArrowsOnScroll);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('scroll', hideArrowsOnScroll);
  }, []);

  return (
    <div className='app_header app_flex'>
      <motion.div whileInView={{ x: [-100, 0], opacity: [0, 1] }} transition={{ duration: 0.5 }} className='app__header-info'>
        <div className='app__header-badge'>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Hello, I am</p>
            <h3>ABUBAKR AHMED</h3>
          </div>
          <div className='badge-cmp app__flex'>
            <div style={{ color:'#fff', marginLeft: 20 }}>
              <h1>front-end Developer</h1> 
             
            </div>
          </div>
          <button className='p-text' type="button" onClick={() => window.location.href = '#contact'}>
            Get In Touch
          </button>
          <div class="arrow arrow-first" onClick={() => window.scrollTo(0, window.innerHeight)}></div>
        <div class="arrow arrow-second" onClick={() => window.scrollTo(0, window.innerHeight)}></div>
        </div>
        
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, 'home');
