// Import necessary libraries and components
import React from 'react';
import { NavigationDots, SocialMedia } from '../components';

// AppWrap is a Higher Order Component (HOC) that wraps a given component with additional elements
// and styles, such as SocialMedia, copyright, and NavigationDots.
// It takes a Component, an idName, and classNames as arguments.
const AppWrap = (Component, idName, classNames) => function HOC() {
  return (
    // Create a div with the given idName and classNames
    <div id={idName} className={`app__container ${classNames}`}>
            {/* Add the SocialMedia component */}
       {/* <SocialMedia />
       {/* Create a wrapper div with additional styles */}
        <div className='app__wrapper app__flex'>
             {/* Render the passed Component */}
            <Component />
             {/* Add a copyright div with two paragraphs */}
             
        </div>
        {/* Add the NavigationDots component with the active prop set to idName */}
       {/*<NavigationDots className="fixed-position" active={idName} /> */}
    </div>
  );
};

// Export the AppWrap HOC
export default AppWrap;